@use "./styles/partials/mixins" as*;
@use "./styles/partials/variables" as*;

*{
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
}


html{
    scroll-behavior: smooth;
    // background-color: $primaryBackgroundColor;
}

.App{
    background-color: $primaryBackgroundColor;
    color: $textColor;
}