@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

.contactme{
    &__container{
        display: flex;
        flex-direction: column;
    }

    &__title{
        width: 100%;
        text-align: center;
    }

    &__form{
        display: flex;
        flex-direction: column;
        color: black;

        @include tablet{
            justify-content: center;
            align-items: center;
        }

        &-input{
            margin-bottom: 0.5rem;
            padding: 0.4rem;
            height: 30px;
            border-color: $secondaryBackgroundColor;
            @include tablet{
                height: 2.5rem;
                width: 80%;
                font-size: 1.2rem;
            }
            @include desktop{
                width: 60%;
            }
        }

        &-textarea{
            padding: 0.4rem;
            border-color: $secondaryBackgroundColor;
            margin-bottom: 1rem;
            @include tablet{
                height: 150px;
                width: 80%;
                font-size: 1.2rem;
            }
            @include desktop{
                width: 60%;
            }
        }

    }

    &__button{
        width: 85px;
        height: 2rem;
        font-size: 1.2rem;
        margin: auto;
        border-color: $primaryHeaderColor;
        background-color: $primaryHeaderColor;
        color: $primaryBackgroundColor;

        @include tablet{
            width: 120px;
            height: 2.5rem;
            font-size: 1.5rem;
        }
    }
}