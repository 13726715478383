@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.landing{
    &__container{
        display: flex;
        flex-direction: column;
        padding: 2rem;
        @include tablet{
            padding: 3rem 5rem;
        }
        @include desktop{
           padding: 3rem 10rem;
        }
    }

    &__wrapper{
        @include desktop{
            height: 70vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 10vh;
        }
    }

    &__hero{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet{
            margin-bottom: 3rem;
        }
        @include desktop{
            flex-direction: row-reverse;
            justify-content: center;
            margin-bottom: 1rem;
        }
    }

    &__picture{
        border: 4px solid $primaryHeaderColor;
        border-radius: 50%;
        box-shadow: 4px 4px 8px;
        margin-bottom: 2rem;
        width: 200px;
        
        @include tablet{
            width: 308px;
            height: 308px;
        }
        @include desktop{
            width: 308px;
            height: 308px;
        }
    }

    &__picture:hover{
        animation: avatarAnimation 4s infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    &__section{
        margin-bottom: 2rem;
        @include tablet{
            margin-bottom: 2rem;
        }
        @include desktop{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 2rem;
            
        }
    }

    &__text{
        margin: 0;
        font-size: 2rem;
        @include desktop{
            font-size: 4rem;
        }
    }
    &__texts{
        margin-bottom: 1rem;
    }

    &__myname{
        color: $primaryHeaderColor;
    }

    &__links{
        font-weight: 600;
        color: $primaryHeaderColor;
        padding: 0.5rem 1rem;
        border: 1px solid $primaryHeaderColor;
        @include desktop{
            font-size: 1.5rem;
        }
        
        
    }

    &__button--cta{
        width: 170px;
        height: 50px;
        margin: auto;
        border: none;
        border-radius: 25px;
        color: $primaryBackgroundColor;
        background-color: $primaryHeaderColor;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    
}

@keyframes avatarAnimation {
    0% {box-shadow: 4px 4px 8px grey}
    25%   {box-shadow: 4px -4px 8px green;}
    50%  {box-shadow: -4px -4px 8px yellow}
    75%  {box-shadow: -4px 4px 8px red}
    100% {box-shadow: 4px 4px 8px grey}
}

