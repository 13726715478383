@use "./variables" as *;

@mixin tablet {
    @media (min-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin slider-container {
    width: 1000px;
    margin: auto;
    overflow: hidden;
    position: relative;
    &::before,
    &::after {
        background: linear-gradient(to right,  rgba($primaryBackgroundColor,1) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
        content: "";
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }
    &::after {
        
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }
}

@mixin slider {
    display: flex;
    list-style-type: none;
    animation: scroll 10s linear infinite;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 1500px;
}