@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

.footer{
    &__container{
        width: 100%;
        
        padding-top: 4rem;
        @include tablet{
            display: flex;
            justify-content: center;
        }
        @include desktop{
            height: 30vh;
        }
    }

    &__icons{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 1rem;
        @include tablet{
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__icon{
        font-size: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        @include tablet{
            font-size: 2rem;
            margin-right: 5rem;
        }
        
    }
}
