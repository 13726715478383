@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

menu,
ol,
ul {
    padding: 0;
}
dd {
    margin: 0;
}
.projects{
    &__container{
        width: 100%;
        display: flex;
        flex-direction: column;
        @include desktop{
            padding-top: 8rem;
        }
    }

    &__title{
        font-size: 3rem;
    }

    &__block{
        margin-bottom: 5rem;
        @include desktop{
            display: flex;
            margin-bottom: 10rem;
        }
    }

    &__divider{
        @include desktop{
            width: 30vw;
        }
    }

    &__name{
        font-size: 2rem;
    }

    &__description{
        margin-bottom: 1rem;
    }

    &__techstack{
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
    }

    &__tech{
        border: 1px solid $secondaryBackgroundColor;
        padding: 4px 8px;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        background-color: $secondaryBackgroundColor;
        color: $primaryHeaderColor;
        filter: brightness(125%);
    }

    &__links{
        margin-bottom: 1rem;
    }

    &__link{
        height: 2rem;
        margin-right: 0.2rem;
        border-color: $secondaryHeaderColor;
        background-color: $primaryHeaderColor;
        color: $primaryBackgroundColor;
        padding: 0.1rem 1rem;
    }
    &__disclaimer{
        font-size: 0.9rem;
        font-style: italic;
    }

    &__image{
        border: 1px solid $secondaryBackgroundColor;
        width: 100%;
        // margin: auto;
        @include tablet{
            width: 75%;
            margin: auto;
        }
        @include desktop{
            width: 15vw;
        }

    }
}

.ffxiv{
    @include desktop{
        width: 30vw;
    }
}