@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

.header{
    &__container{
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;
        font-size: 2rem;
        width: 100%;
        justify-content: space-between;
        @include tablet{
            
        }
        @include desktop {
            padding: 0 4rem;
            justify-content: space-between;
        }
        position: sticky;
        top: 0;
        background-color: $primaryBackgroundColor;
        z-index: 3;
    }

    &__links{
        
        color: $textColor;
        display: none;
        text-decoration: none;
        list-style: none;
        @include desktop{
            display: flex;
        }

    }
    &__link{
        // border: 1px solid $textColor;
        padding: 0 1rem;
    }
    &__menu{
        display: flex;
        @include desktop{
            display: none;
        }

    }
    &__title{
        margin: 0;
        font-size: 1.5rem;
    }

    &__icons{
        display: flex;
        align-items: center;
        justify-self: flex-end;
        justify-content: flex-end;
    }
    &__icon{
        display: flex;
        margin-left: 1rem;
        @include tablet{
            font-size: 2.5rem;
            width: 50px;
            margin-left: 1.5rem;
        }
        @include desktop{
            font-size: 3rem;
            margin-left: 3rem;
        }
    }

}

.xxx{
    width: 294px;
}

