@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

.whoamI{
    &__container{
        width: 100%;
        margin-top: 6rem;
        margin-bottom: 5rem;
        @include tablet{
            margin-bottom: 3rem;
        }
        @include desktop{
            padding-top: 8rem;
        }
    }

    &__wrapper{
        @include desktop{
            display: flex;
        }
    }

    &__title{
        font-size: 2rem;
        @include desktop{
            margin-top: 0;
        }
    }

    &__catchphrase{
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    &__article{
        margin-bottom: 3rem;
    }

    &__icons{
        &-container{
            padding-top: 8rem;
        }
        &-title{
            font-size: 2rem;
        }

        &-slider{
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;
        }
    }

    &__icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2rem;
        margin-bottom: 2rem;
        transition: 0.3s;
        @include tablet{
            margin-left: 4rem;
            margin-bottom: 1rem;
        }
        &-image{
            height: 50px;
            
        }
        &:hover{
            transform: scale(1.5);
        }
    }

    &__image{
        width: 100%;
        margin-bottom: 3rem;
        
        @include desktop{
            width: 50vw;
            margin-bottom: 0;
        }
        
    }
}

@keyframes scroll {
    0%{transform: translateX(0);}
    100%{transform: translateX(-750px);}
}