//breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;

//colors

$primaryBackgroundColor: #EAE7DC;
$secondaryBackgroundColor: #D8C3A5;
$textColor: #8E8D8A;
$primaryHeaderColor: #E85A4F;
$secondaryHeaderColor: #E98074;