@use "../../styles/partials/mixins" as*;
@use "../../styles/partials/variables" as*;

.navbar{
    &__container{
        background-color: $secondaryBackgroundColor;
        width: 250px;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        padding: 0.5rem;
        left: -100%;
        transition: 850ms;
        font-size: 1.5rem;
        border-right: 1px solid #2196F3;
    }

    &__container.active {
        left: 0;
        transition: 250ms;
    }

    &__text{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        height: 60px;
    }

    &__text a{
        text-decoration: none;
        color: $textColor;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding-left: 1rem;
    }

    &__text a:hover{
        background-color: rgba(black,0.2);
        width: 100%;
    }

    &__items{
        width: 100%;
        padding: 1rem 1rem;
        margin: 0;
    }

    &__toggle{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 1rem;
    }

    &__span{
        margin-left: 16px;
    }

    &__item--bar{
        margin-left: 0rem;
        font-size: 2rem;
    }
}